import { render, staticRenderFns } from "./Service-Accounts-Table.vue?vue&type=template&id=20eaa9ba&scoped=true&"
import script from "./Service-Accounts-Table.vue?vue&type=script&lang=js&"
export * from "./Service-Accounts-Table.vue?vue&type=script&lang=js&"
import style0 from "./Service-Accounts-Table.vue?vue&type=style&index=0&id=20eaa9ba&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "20eaa9ba",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VChip } from 'vuetify/lib/components/VChip';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VSubheader } from 'vuetify/lib/components/VSubheader';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VTextarea } from 'vuetify/lib/components/VTextarea';
import { VTooltip } from 'vuetify/lib/components/VTooltip';
installComponents(component, {VBtn,VChip,VDataTable,VIcon,VList,VListItem,VListItemSubtitle,VListItemTitle,VSubheader,VTextField,VTextarea,VTooltip})
